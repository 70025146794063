import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";

import slide_image_1 from "../../../assets/images/logos/angular.png";
import slide_image_2 from "../../../assets/images/logos/capacitor.png";
import slide_image_3 from "../../../assets/images/logos/core.png";
import slide_image_4 from "../../../assets/images/logos/docker.png";
import slide_image_5 from "../../../assets/images/logos/ico.png";
import slide_image_6 from "../../../assets/images/logos/micros.png";
import slide_image_7 from "../../../assets/images/logos/ocelot_logo.png";
import slide_image_8 from "../../../assets/images/logos/react.png";

function App() {
  return (
    <div className="container">
      <h1 className="sameH">Advanced technologies we work with</h1>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        className="swiper_container"
      >
        <SwiperSlide>
          <div className="card card2">
            <img src={slide_image_1} alt="slide_image" />
            <h1></h1>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card card2">
            <img src={slide_image_2} alt="slide_image" />
            <h1></h1>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card card2">
            <img src={slide_image_3} alt="slide_image" />
            <h1></h1>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card card2">
            <img src={slide_image_4} alt="slide_image" />
            <h1></h1>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card card2">
            <img src={slide_image_5} alt="slide_image" />
            <h1></h1>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card card2">
            <img src={slide_image_6} alt="slide_image" />
            <h1></h1>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card card2">
            <img src={slide_image_7} alt="slide_image" />
            <h1></h1>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card card2">
            <img src={slide_image_8} alt="slide_image" />
            <h1></h1>
          </div>
        </SwiperSlide>

        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
}

export default App;
