import React, { useEffect, useRef } from "react";
import styles from "./contnt.module.css";
import Footer from "../../../components/HomePage/Footer";
import Navbar from "../../../components/NavBar";
export default function Index() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div>
        <Navbar />
        <div className={styles.main}>
          <div className={`${styles.main_most}`}>
            <div className={`${styles.main_in}`}>
              <div>
                <h3
                  ref={(el) => (elementsRef.current[0] = el)}
                  className={`${styles.fadein}`}
                >
                  Lorem ipsum dolor sit, amet
                </h3>
              </div>
              <div>
                <p
                  ref={(el) => (elementsRef.current[42] = el)}
                  className={`${styles.fadein}`}
                >
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Doloremque, aliquid explicabo ex magni veritatis dolores illum
                  doloribus dicta optio accusantium possimus id velit, rem nam
                  sint quas magnam voluptas perspiciatis.
                </p>
                <p
                  ref={(el) => (elementsRef.current[2] = el)}
                  className={`${styles.fadein}`}
                >
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Dicta, voluptatum.
                </p>
                <p
                  ref={(el) => (elementsRef.current[3] = el)}
                  className={`${styles.fadein}`}
                >
                  Lorem ipsum dolor sit.
                </p>
                <button
                  ref={(el) => (elementsRef.current[4] = el)}
                  className={`${styles.fadein}`}
                >
                  Lorem, ipsum dolor.
                </button>
              </div>
            </div>

            <hr className={`${styles.hr}`} />
            <div className={styles.main_below}>
              <div className={styles.below_flex}>
                <div>
                  <h2
                    ref={(el) => (elementsRef.current[5] = el)}
                    className={`${styles.fadein}`}
                  >
                    Lorem, ipsum.
                  </h2>
                </div>
                <div>
                  <h5
                    ref={(el) => (elementsRef.current[6] = el)}
                    className={`${styles.fadein}`}
                  >
                    Lorem, ipsum.
                  </h5>
                  <p
                    ref={(el) => (elementsRef.current[40] = el)}
                    className={`${styles.fadein}`}
                  >
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Architecto iste vero aliquid autem, error consequatur
                    ratione id mollitia dolore similique?
                  </p>
                  <h5
                    ref={(el) => (elementsRef.current[7] = el)}
                    className={`${styles.fadein}`}
                  >
                    Lorem ipsum dolor sit.
                  </h5>
                  <ul>
                    <li
                      ref={(el) => (elementsRef.current[30] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit, amet consectetur adipisicing.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[31] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quae tempore ex nemo!
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[32] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Dolor.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[33] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Optio temporibus veritatis eligendi asperiores.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[34] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Possimus voluptatibus vitae reiciendis asperiores error!
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[35] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit, amet consectetur adipisicing.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[36] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quae tempore ex nemo!
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[37] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Dolor.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[38] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Optio temporibus veritatis eligendi asperiores.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[39] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Possimus voluptatibus vitae reiciendis asperiores error!
                    </li>
                  </ul>
                  <h5
                    ref={(el) => (elementsRef.current[8] = el)}
                    className={`${styles.fadein}`}
                  >
                    Lorem ipsum dolor sit.
                  </h5>
                  <ul>
                    <li
                      ref={(el) => (elementsRef.current[9] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit, amet consectetur adipisicing.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[10] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quae tempore ex nemo!
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[11] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Dolor.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[12] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Optio temporibus veritatis eligendi asperiores.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[13] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Possimus voluptatibus vitae reiciendis asperiores error!
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[14] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit, amet consectetur adipisicing.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[15] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quae tempore ex nemo!
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[16] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Dolor.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[17] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Optio temporibus veritatis eligendi asperiores.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[18] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Possimus voluptatibus vitae reiciendis asperiores error!
                    </li>
                  </ul>
                  <h5
                    ref={(el) => (elementsRef.current[19] = el)}
                    className={`${styles.fadein}`}
                  >
                    Lorem ipsum dolor sit.
                  </h5>
                  <ul>
                    <li
                      ref={(el) => (elementsRef.current[20] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit, amet consectetur adipisicing.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[21] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quae tempore ex nemo!
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[22] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Dolor.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[23] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Optio temporibus veritatis eligendi asperiores.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[24] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Possimus voluptatibus vitae reiciendis asperiores error!
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[25] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit, amet consectetur adipisicing.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[26] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quae tempore ex nemo!
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[27] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Dolor.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[28] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Optio temporibus veritatis eligendi asperiores.
                    </li>
                    <li
                      ref={(el) => (elementsRef.current[29] = el)}
                      className={`${styles.fadein}`}
                    >
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Possimus voluptatibus vitae reiciendis asperiores error!
                    </li>
                  </ul>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
