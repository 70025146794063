import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/Logo-Small-F.png";
import logo1 from "../../assets/images/F Logo..png";
import styles from "./styles.module.css";
import { Link } from "react-scroll";
const Index = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`${styles.navbar} ${scrolled ? styles.scrolled : ""}`}>
      <div className={`${styles.container}`}>
        <div className={`${styles.logo} ${scrolled ? styles.smallLogo : ""}`}>
          <NavLink to="/">
            <Logo scrolled={scrolled} />
          </NavLink>
        </div>
        <div className={`${styles.menu_icon}`} onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div
          className={`${styles.navElements} ${showNavbar ? styles.active : ""}`}
        >
          <ul>
            <li>
              <Link
                onClick={handleShowNavbar}
                to="About-Section"
                smooth={true}
                duration={500}
              >
             
                Why Finnavator
              </Link>
            </li>
            <li>
              <Link
                onClick={handleShowNavbar}
                to="Services-Section"
                smooth={true}
                duration={500}
              >
               What We are offering
              </Link>
            </li>
            {/* <li>
              <NavLink onClick={handleShowNavbar} to="/">
                Our Work
              </NavLink>
            </li> */}
             <li>
              <Link
                onClick={handleShowNavbar}
                to="Solutions-Section"
                smooth={true}
                duration={500}
              >
              Our Solutions
              </Link>
            </li>
            <li>
              <Link
                onClick={handleShowNavbar}
                to="Contact-Section"
                smooth={true}
                duration={500}
              >
                Contact Us
              </Link>
            </li>
          </ul>
          <div
            className={`${styles.menu_icon} ${styles.X}`}
            onClick={handleShowNavbar}
          >
            <div className={`${styles.addresses}`}>
              <div className="addresses_items">
                {/* <h5>KSA</h5>
                <p>
                  Lorem ipsum dolor <br /> sit amet, consectetur <br />{" "}
                  adipisicing elit. Sequi odit dolorem <br /> nobis aspernatur,
                  quasi <br /> cupiditate!
                </p> */}
              </div>
              <div className="addresses_items">
                <h5>UAE</h5>
                <p>
                  Dubai Silicon Oasis <br />
                  DDP <br />
                  Building A1 <br />
                  Dubai, United Arab Emirates <br />
                </p>
              </div>
            </div>
            <span>X</span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Index;

const Hamburger = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="24"
    viewBox="0 0 52 24"
  >
    <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="30"
        height="3"
        rx="2"
        transform="translate(304 47)"
        fill="#fff"
      />
      <rect
        id="Rectangle_5"
        data-name="Rectangle 5"
        width="30"
        height="3"
        rx="2"
        transform="translate(304 67)"
        fill="#fff"
      />
      <rect
        id="Rectangle_4"
        data-name="Rectangle 4"
        width="25"
        height="3"
        rx="2"
        transform="translate(308 57)"
        fill="#fff"
      />
    </g>
  </svg>
);

const Logo = ({ scrolled }) => <img src={scrolled ? logo : logo1} alt="Logo" />;
