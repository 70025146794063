import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import image1 from "../../../assets/Information technology.png";
import image2 from "../../../assets/Cyber security.png";
import image3 from "../../../assets/Risk & governance.png";
import image4 from "../../../assets/Business continuity.png";

const Index = () => {
  const navigate = useNavigate();
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag, index) => {
        if (tag) {
          const rect = tag.getBoundingClientRect();

          if (rect.top < window.innerHeight) {
            tag.classList.add(styles.visible);
          } else {
            tag.classList.remove(styles.visible);
          }
        }
      });
    };

    const throttledHandleScroll = throttle(handleScroll, 200);

    document.addEventListener("scroll", throttledHandleScroll);
    handleScroll(); // Trigger the scroll handler on mount to check the initial visibility

    return () => {
      document.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);
  const data = [
    {
      text: "Information Technology",
      image: image1,
      hoverText: "Information Technology",
      hoverTextPopup:
        "We help you harness the power of emerging technologies to modernize your operations, enhance customer experiences, and stay ahead of the curve in a rapidly evolving digital landscape.Business Process Automation Robotic Process Automation…",
      path: "/information-technology",
    },
    {
      text: "Cybersecurity",
      image: image2,
      hoverText: "Cybersecurity",
      hoverTextPopup:
        "At Finnovator IT Solutions, we understand the critical importance of cybersecurity in safeguarding your organization's digital assets and data. Our cybersecurity consultancy services are designed to help businesses mitigate cyber risks, strengthen their security posture, and achieve compliance with industry regulations. With our team of experienced cybersecurity experts, we offer a comprehensive range of consultancy services tailored to meet your organization's specific needs and challenges.",
      path: "/cyber-security",
    },
    {
      text: "Risk & Governance",
      image: image3,
      hoverText: "Risk & Governance",
      hoverTextPopup:
        "At Finnovator IT Solutions, we recognize the importance of effective IT risk management and governance in ensuring the security, reliability, and compliance of your organization's IT environment. Our IT Risk & Governance Services are designed to help businesses identify, assess, and mitigate IT risks while ensuring alignment with regulatory…",
      path: "/risk-governance",
    },
    {
      text: " Business Continuity",
      image: image4,
      hoverText: " Business Continuity",
      hoverTextPopup:
        "At Finnovator IT Solutions, we understand the critical importance of business continuity in ensuring the resilience and continuity of your organization's operations, especially in the face of unforeseen disruptions and disasters. Our Business Continuity Management System (BCMS) services…",
      path: "/busines-continuity",
    },
  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const updateRotation = () => {
      data.forEach((item, badgeIndex) => {
        const elements = document.querySelectorAll(
          `.${styles.badgeContainer}:nth-child(${badgeIndex + 1}) .${
            styles.badge__char
          }`
        );
        const step = 330 / elements.length;

        elements.forEach((elem, i) => {
          elem.style.setProperty("--char-rotate", `${i * step}deg`);
        });
      });
    };

    updateRotation();
  }, [data, hoveredIndex]);
  const handleServiceClick = (path) => {
    navigate(path);
  };
  return (
    <div id="Services-Section" className={`${styles.services}`}>
      <div
        ref={(el) => (elementsRef.current[1111] = el)}
        className={`${styles.fadein}`}
      >
        <h2 className="sameH">What We are offering</h2>
      </div>
      <div
        ref={(el) => (elementsRef.current[0] = el)}
        className={`${styles.fadein} sameHh`}
      >
        Services
      </div>
      <div
        ref={(el) => (elementsRef.current[1] = el)}
        className={`${styles.fadein} ${styles.container}`}
      >
        {data.map((item, index) => (
          <div
            key={index}
            className={`${styles.badgeContainer} ${
              hoveredIndex === index ? styles.badgeContainerHovered : ""
            }`}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            onClick={() => handleServiceClick(item.path)}
          >
            <div className={styles.badge}>
              {(hoveredIndex === index ? item.hoverText : item.text)
                .split("")
                .map((char, charIndex) => (
                  <span key={charIndex} className={styles.badge__char}>
                    {char}
                  </span>
                ))}
            </div>
            <img
              className={`${styles.badge__emoji} ${
                hoveredIndex === index ? styles.badge__emojiHovered : ""
              }`}
              src={item.image}
              width="72"
              height="72"
              alt=""
            />
            {hoveredIndex === index && (
              <div className={styles.popup}>
                <p className="sameP">{item.hoverTextPopup}</p>{" "}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Index;
// Utility function to throttle the scroll event handler
function throttle(fn, wait) {
  let time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
}
