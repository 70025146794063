import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";

const Index = () => {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.main_cont}>
      <div className={styles.contact}>
        <div className={styles.contact_flex}>
          <div>
            <h3
              ref={(el) => (elementsRef.current[0] = el)}
              className={styles.fadein}
            >
              Get in touch.
            </h3>
            <p
              ref={(el) => (elementsRef.current[1] = el)}
              className={styles.fadein}
            >
              Tell us about you and your project
            </p>
          </div>
          <div>
            <form action="">
              <ul>
                {[
                  "Tell us your name!",
                  "Your email",
                  "Your phone",
                  "Your Company",
                  "What is your requirement?",
                ].map((placeholder, index) => (
                  <li
                    key={index + 2}
                    ref={(el) => (elementsRef.current[index + 2] = el)}
                    className={styles.fadein}
                  >
                    {index < 4 ? (
                      <input type="text" placeholder={placeholder} />
                    ) : (
                      <textarea placeholder={placeholder}></textarea>
                    )}
                  </li>
                ))}
              </ul>
              <button
                ref={(el) => (elementsRef.current[7] = el)}
                className={styles.fadein}
              >
                Send inquiry
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
